<template>
  <div
    :class="{
      iphonebtn: true,
      bigbtn: Number(dataitem.link_type) == 1,
      bighaf: Number(dataitem.link_type) == 2,
      bigsqua: Number(dataitem.link_type) == 3,
      bigwu: Number(dataitem.link_type) == 4,
      bigwu2:
        Number(dataitem.link_type) == 4 &&
        Number(layoutbg.button_type_id) == 9 &&
        dataitem.text_color == null,
      bigwu5:
        Number(dataitem.link_type) == 4 &&
        Number(layoutbg.button_type_id) != 9 &&
        dataitem.text_color == null,
      bigwu1:
        Number(dataitem.link_type) == 4 &&
        Number(layoutbg.button_type_id) == 12,
      bigwu3:
        Number(dataitem.link_type) == 4 && Number(layoutbg.button_type_id) == 9
    }"
  >
    <!-- <div
      @click="cilicknumber"
      v-if="type2"
      :style="{
        color: '#fff',
        background: '#000',
        padding: '0.2rem',
        opacity: dataitem.opacity
      }"
      :class="{
        btnitem: true,
        smell: dataitem.font_size == 'smell',
        transapre: dataitem.button_type === 'Y'
      }"
    >
      <el-image
        v-if="dataitem.interlinkage_image != ''"
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          btnimg2: dataitem.icon_wz == 3
        }"
        :src="dataitem.interlinkage_image"
      ></el-image>
      <span
        :class="{
          btntext: true,
          line: dataitem.interlinkage_image != ''
        }"
      >
        {{ dataitem.title }}</span
      >
    </div> -->
    <!-- type1widthmax -->
    <!-- 普通按钮一 -->
    <div
      @click="cilicknumber"
      v-if="
        layoutbg.button_type_id === 'false' && Number(dataitem.link_type) != 4
      "
      :style="{
        color: dataitem.text_color,
        background: dataitem.button_color,
        opacity: dataitem.opacity
      }"
      :class="{
        btnitem: true,
        btn1: type1,
        btn2: layoutbg.button_circle === '1',
        btn3: layoutbg.button_outline === '1',
        btn4: layoutbg.button_social === '1',
        btn5: layoutbg.button_corner === '1',
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big',
        transapre: dataitem.button_type === 'Y'
      }"
    >
      <el-image
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          btnimg2: dataitem.icon_wz == 3,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        v-if="dataitem.interlinkage_image != ''"
        :src="dataitem.interlinkage_image"
      ></el-image>
      <span
        v-if="dataitem.title != ''"
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3,
          line: dataitem.interlinkage_image != ''
        }"
      >
        {{ dataitem.title }}</span
      >
    </div>
    <div
      @click="cilicknumber"
      v-if="type3"
      :style="{
        color: dataitem.text_color,
        background: dataitem.button_color,
        opacity: dataitem.opacity
      }"
      :class="{
        btnitem: true,
        btn1: type1,
        btn2: layoutbg.button_circle === '1',
        btn3: layoutbg.button_outline === '1',
        btn4: layoutbg.button_social === '1',
        btn5: layoutbg.button_corner === '1',
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big',
        transapre: dataitem.button_type === 'Y'
      }"
    >
      <el-image
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          btnimg2: dataitem.icon_wz == 3,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        v-if="dataitem.interlinkage_image != ''"
        :src="dataitem.interlinkage_image"
      ></el-image>
    </div>
    <div
      @click="cilicknumber"
      v-else-if="type4"
      :style="{
        color: dataitem.text_color,
        background: dataitem.button_color,
        opacity: dataitem.opacity
      }"
      :class="{
        btnitem: true,
        btn1: type1,
        btn2: layoutbg.button_circle === '1',
        btn3: layoutbg.button_outline === '1',
        btn4: layoutbg.button_social === '1',
        btn5: layoutbg.button_corner === '1',
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big',
        transapre: dataitem.button_type === 'Y'
      }"
    >
      <el-image
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        v-if="dataitem.interlinkage_image != ''"
        :src="dataitem.interlinkage_image"
      ></el-image>
      <span
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3,
          line: dataitem.interlinkage_image != ''
        }"
      >
        {{ dataitem.title }}</span
      >
    </div>
    <!-- 普通按钮2 -->
    <el-image
      @click="cilicknumber"
      v-if="
        dataitem.link_type == 4 &&
          layoutbg.button_type_id != 9 &&
          dataitem.text_color != null &&
          layoutbg.button_type_id != 12
      "
      class="wuwidth"
      :src="dataitem.interlinkage_image"
    ></el-image>
    <div
      @click="cilicknumber"
      class="special-btn1"
      v-if="layoutbg.button_type_id == 1 && dataitem.link_type != 4"
    >
      <div
        :class="{
          left: dataitem.title_type == 3,
          right: dataitem.title_type == 2,
          mini: dataitem.font_size == 'mini',
          smell: dataitem.font_size == 'smell',
          big: dataitem.font_size == 'big'
        }"
      >
        <img
          :style="{ 'margin-top': !type5 ? '-0.1rem !important' : '0px' }"
          v-if="dataitem.interlinkage_image != ''"
          :class="{
            btnimg: !type5,
            btnimg1: type5,
            imgleft: dataitem.icon_wz == 4,
            imgright: dataitem.icon_wz == 2
          }"
          :src="dataitem.interlinkage_image"
          fit="contain"
        />
        <span
          :class="{
            btntext: true,
            block: dataitem.icon_wz == 3 || dataitem.link_type == 3
          }"
        >
          {{ dataitem.title }}</span
        >
      </div>
    </div>
    <div
      @click="cilicknumber"
      class="special-btn1"
      v-if="layoutbg.button_type_id == 2 && dataitem.link_type != 4"
    >
      <div
        :class="{
          left: dataitem.title_type == 3,
          right: dataitem.title_type == 2,
          mini: dataitem.font_size == 'mini',
          smell: dataitem.font_size == 'smell',
          big: dataitem.font_size == 'big'
        }"
        style="background: #fff;color:#000"
      >
        <img
          :style="{ 'margin-top': !type5 ? '-0.1rem !important' : '0px' }"
          v-if="dataitem.interlinkage_image != ''"
          :class="{
            btnimg: !type5,
            btnimg1: type5,
            imgleft: dataitem.icon_wz == 4,
            imgright: dataitem.icon_wz == 2
          }"
          :src="dataitem.interlinkage_image"
          fit="contain"
        />
        <span
          :class="{
            btntext: true,
            block: dataitem.icon_wz == 3 || dataitem.link_type == 3
          }"
        >
          {{ dataitem.title }}</span
        >
      </div>
    </div>
    <div
      @click="cilicknumber"
      :style="{ color: dataitem.text_color, background: dataitem.button_color }"
      :class="{
        'special-btn2': true,
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big'
      }"
      v-if="layoutbg.button_type_id == 3 && dataitem.link_type != 4"
    >
      <img
        v-if="dataitem.interlinkage_image != ''"
        :style="{ 'margin-top': !type5 ? '-0.1rem !important' : '0px' }"
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        :src="dataitem.interlinkage_image"
        fit="contain"
      />
      <span
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3
        }"
      >
        {{ dataitem.title }}</span
      >
    </div>
    <div
      @click="cilicknumber"
      :style="{ color: dataitem.text_color, background: dataitem.button_color }"
      :class="{
        'special-btn3': true,
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big'
      }"
      class="special-btn3"
      v-if="layoutbg.button_type_id == 4 && dataitem.link_type != 4"
    >
      <img
        v-if="dataitem.interlinkage_image != ''"
        :style="{ 'margin-top': !type5 ? '-0.1rem !important' : '0px' }"
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        :src="dataitem.interlinkage_image"
        fit="contain"
      />
      <span
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3
        }"
      >
        {{ dataitem.title }}</span
      >
    </div>
    <div
      @click="cilicknumber"
      class="special-btn4"
      :style="setStyle(dataitem.button_color, dataitem.text_color)"
      v-if="layoutbg.button_type_id == 5 && dataitem.link_type != 4"
    >
      <div
        :class="{
          left: dataitem.title_type == 3,
          right: dataitem.title_type == 2,
          mini: dataitem.font_size == 'mini',
          smell: dataitem.font_size == 'smell',
          big: dataitem.font_size == 'big'
        }"
      >
        <img
          :style="{ 'margin-top': !type5 ? '-0.1rem !important' : '0px' }"
          v-if="dataitem.interlinkage_image != ''"
          :class="{
            btnimg: !type5,
            btnimg1: type5,
            imgleft: dataitem.icon_wz == 4,
            imgright: dataitem.icon_wz == 2
          }"
          :src="dataitem.interlinkage_image"
          fit="contain"
        />
        <span
          :class="{
            btntext: true,
            block: dataitem.icon_wz == 3 || dataitem.link_type == 3
          }"
        >
          {{ dataitem.title }}</span
        >
      </div>
    </div>
    <div
      @click="cilicknumber"
      class="special-btn5"
      v-if="layoutbg.button_type_id == 8 && dataitem.link_type != 4"
      :style="{ color: dataitem.text_color }"
    >
      <div class="item">
        <img
          v-if="dataitem.interlinkage_image != ''"
          :class="{
            btnimg1: true,
            imgleft: dataitem.icon_wz == 4,
            imgright: dataitem.icon_wz == 2
          }"
          :src="dataitem.interlinkage_image"
          fit="contain"
        />
        <span
          :class="{
            btntext: true,
            block: dataitem.icon_wz == 3 || dataitem.link_type == 3
          }"
        >
          {{ dataitem.title }}</span
        >
      </div>
    </div>
    <div
      @click="cilicknumber"
      v-if="layoutbg.button_type_id == 9 && dataitem.text_color != null"
      :style="{ color: dataitem.text_color }"
      :class="{
        'special-btn6': true,
        wu: dataitem.link_type == 4,
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big',
        btn2: layoutbg.button_circle === '1',
        btn6: layoutbg.button_outline === '1',
        btn4: layoutbg.button_social === '1',
        btn5: layoutbg.button_corner === '1'
      }"
    >
      <img
        v-if="dataitem.interlinkage_image != ''"
        :class="{
          btnimg1: true,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        :src="dataitem.interlinkage_image"
        fit="contain"
      />

      <span
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3
        }"
      >
        {{ dataitem.title }}</span
      >
    </div>
    <img
      @click="cilicknumber"
      v-if="
        dataitem.text_color == null &&
          layoutbg.button_type_id == 9 &&
          dataitem.link_type == 4
      "
      :class="{
        btnimg4: true
      }"
      :src="dataitem.interlinkage_image"
      fit="contain"
    />
    <img
      @click="cilicknumber"
      v-if="
        dataitem.text_color == null &&
          layoutbg.button_type_id != 9 &&
          dataitem.link_type == 4
      "
      :class="{
        btnimg4: true
      }"
      :src="dataitem.interlinkage_image"
      fit="contain"
    />
    <div
      @click="cilicknumber"
      v-if="layoutbg.button_type_id == 10"
      :style="{
        color: dataitem.text_color,
        background: dataitem.button_color,
        border: ' 0.053333rem solid #6b964f'
      }"
      :class="{
        btnitem: true,
        btn1: type1,
        btn2: layoutbg.button_circle === '1',
        btn6: layoutbg.button_outline === '1',
        btn4: layoutbg.button_social === '1',
        btn5: layoutbg.button_corner === '1',
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big',
        transapre: dataitem.button_type === 'Y'
      }"
    >
      <el-image
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          btnimg2: dataitem.icon_wz == 3,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        v-if="dataitem.interlinkage_image != ''"
        :src="dataitem.interlinkage_image"
      ></el-image>
      <span
        v-if="dataitem.title != ''"
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3,
          line: dataitem.interlinkage_image != ''
        }"
      >
        {{ dataitem.title }}</span
      >
    </div>
    <div
      @click="cilicknumber"
      v-if="layoutbg.button_type_id == 11"
      :style="{
        color: dataitem.text_color
      }"
      :class="{
        'special-btn7': true,
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big'
      }"
    >
      <el-image
        :class="{
          btnimg: !type5,
          btnimg1: type5,
          btnimg2: dataitem.icon_wz == 3,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        v-if="dataitem.interlinkage_image != ''"
        :src="dataitem.interlinkage_image"
      ></el-image>
      <span
        v-if="dataitem.title != ''"
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3,
          line: dataitem.interlinkage_image != ''
        }"
      >
        {{ dataitem.title }}</span
      >
      <div class="rightboder"></div>
    </div>
    <div
      @click="cilicknumber"
      v-if="layoutbg.button_type_id == 12 && dataitem.text_color != null"
      :style="{ color: dataitem.text_color, background: dataitem.button_color }"
      :class="{
        'special-btn6': true,
        wu: dataitem.link_type == 4,
        left: dataitem.title_type == 3,
        right: dataitem.title_type == 2,
        mini: dataitem.font_size == 'mini',
        smell: dataitem.font_size == 'smell',
        big: dataitem.font_size == 'big',
        btn2: layoutbg.button_circle === '1',
        btn6: layoutbg.button_outline === '1',
        btn4: layoutbg.button_social === '1',
        btn5: layoutbg.button_corner === '1'
      }"
    >
      <img
        v-if="dataitem.interlinkage_image != ''"
        :class="{
          btnimg1: true,
          imgleft: dataitem.icon_wz == 4,
          imgright: dataitem.icon_wz == 2
        }"
        :src="dataitem.interlinkage_image"
        fit="contain"
      />

      <span
        :class="{
          btntext: true,
          block: dataitem.icon_wz == 3 || dataitem.link_type == 3
        }"
      >
        {{ dataitem.title }}</span
      >
    </div>
  </div>
</template>

<script>
import { relevancedj } from "../../api/iphone";
export default {
  name: "iphonebtn",
  props: { layoutbg: { type: Object }, dataitem: { type: Object } },
  components: {},

  data() {
    return {
      type1: true,
      type2: false,
      type3: false,
      type4: false,
      type5: false
    };
  },
  watch: {
    layoutbg: {
      handler: function(val, oldVal) {
        if (this.layoutbg.text_color === "" && this.layoutbg.text_img === "") {
          this.type2 = true;
        } else if (
          this.layoutbg.text_color !== "" ||
          this.layoutbg.text_img !== ""
        ) {
          if (
            Number(this.layoutbg.button_type_id) === false &&
            this.dataitem.title == null &&
            Number(this.dataitem.link_type) !== 4
          ) {
            this.type3 = true;
          }
          if (
            Number(this.layoutbg.button_type_id) === false &&
            this.dataitem.title != null &&
            Number(this.dataitem.link_type) !== 4
          ) {
            this.type4 = true;
          }
        }
        if (
          Number(this.dataitem.icon_wz) !== 4 &&
          Number(this.dataitem.icon_wz) !== 2 &&
          Number(this.dataitem.title_type) !== 2 &&
          Number(this.dataitem.title_type) !== 3
        ) {
          this.type5 = true;
        } else if (
          Number(this.dataitem.title_type) === 3 &&
          Number(this.dataitem.icon_wz) === 4
        ) {
          this.type5 = true;
        } else if (
          Number(this.dataitem.title_type) === 2 &&
          Number(this.dataitem.icon_wz) === 2
        ) {
          this.type5 = true;
        }
      }
    },
    deep: true,
    immediate: true
  },
  created() {},
  mounted() {},
  methods: {
    setStyle(bgcolor, textcolor) {
      return {
        "--my-color": bgcolor,
        "--text-color": textcolor
      };
    },
    cilicknumber(e) {
      relevancedj({
        layout_id: this.dataitem.id,
        meber_id: this.dataitem.user_id
      }).then(res => {
        if (res.code === 1) {
        }
      });
      if (this.dataitem.url_one !== null) {
        window.open(this.dataitem.url_one, "_blank");
      } else {
        window.open(this.dataitem.url, "_blank");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.iphonebtn {
  width: 100%;
  display: inline-block;
}
.bigbtn {
  width: 100%;
}
.bighaf {
  width: 46%;
  margin-right: 2%;
}
.bigsqua {
  width: 23%;
  margin-right: 1%;
}
.bigwu {
  margin: 0.033333rem;
  height: 0.8rem;
  width: 0.8rem;
}

.btn1 {
  width: 100%;
  // height: 1.333333rem;
  // line-height: 1.333333rem;
  margin-top: 0.066667rem;
  padding: 0.266667rem 0.133333rem;
  background: #ffffff;
  border-radius: 0.053333rem;
  color: #333;
  font-size: 0.346667rem;
}
.btn2 {
  border-radius: 5.866667rem !important;
}
.btn3 {
  border: 1px solid #ffffff !important;
  background: transparent !important;
  color: #fff;
}
.btn4 {
  background: transparent;
  border: 0px;
}
.btn5 {
  border-radius: 0.266667rem !important;
}
.btn6 {
  // border: 0.053333rem solid #6b964f;
  border-radius: 0.266667rem !important;
}
.btnitem {
  margin-bottom: 0.373333rem !important;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 9999999999999999999999999999999;
  border-radius: 0.106667rem;
  .btnimg {
    width: 0.666667rem;
    height: 0.666667rem;
    vertical-align: middle;
    margin: 0 0.133333rem;
  }

  .imgleft {
    float: left;
    // margin-top: 5px;
  }
  .imgright {
    float: right;
    // margin-top: 5px;
  }
}
.special-btn1 {
  width: 100%;
  margin-bottom: 0.373333rem !important;
  // height: 1.36rem;
  padding: 0.133333rem;
  border: 0.053333rem solid #ffffff;
  position: relative;
  z-index: 9999999999999999999999999999999;
  .btnimg {
    width: 0.666667rem;
    height: 0.666667rem;
    vertical-align: middle;
    margin: 0 0.133333rem;
  }
  div {
    padding: 0.266667rem 0.066667rem;
    width: 98%;
    margin: auto;
    // height: 1.066667rem;
    background: rgba(161, 198, 209, 0.59);
    font-size: 0.373333rem;
    color: #ffffff;
    // line-height: 1.066667rem;
    letter-spacing: 5px;
    margin-top: 0.093333rem;
  }
}
.special-btn2 {
  margin-bottom: 0.373333rem !important;
  width: 100%;
  padding: 0.266667rem 0.066667rem;
  // height: 1.306667rem;
  border: 0.053333rem solid #5a5a5a;
  position: relative;
  font-size: 0.373333rem;
  color: #ffffff;
  // line-height: 1.306667rem;
  letter-spacing: 5px;
  margin-top: 0.093333rem;
  position: relative;
  z-index: 9999999999999999999999999999999;
  .btnimg {
    width: 0.666667rem;
    height: 0.666667rem;
    vertical-align: middle;
    margin: 0 0.133333rem;
  }
  &::after {
    content: "";
    position: absolute;
    height: 0.72rem;
    width: 0.933333rem;
    border-top: 0.053333rem solid #5a5a5a;
    border-left: 0.053333rem solid #5a5a5a;
    top: -0.18rem;
    left: -0.18rem;
  }
  &::before {
    content: "";
    position: absolute;
    height: 0.72rem;
    width: 0.933333rem;
    border-bottom: 0.053333rem solid #5a5a5a;
    border-right: 0.053333rem solid #5a5a5a;
    bottom: -0.18rem;
    right: -0.18rem;
  }
}
.special-btn3 {
  position: relative;
  z-index: 9999999999999999999999999999999;
  width: 100%;
  // height: 1.28rem;
  font-size: 0.373333rem;
  // line-height: 1.28rem;
  padding: 0.266667rem 0.066667rem;
  letter-spacing: 5px;
  margin-bottom: 0.373333rem !important;
  border-top: 0.04rem solid #000;
  border-bottom: 0.106667rem solid #000;
  border-left: 0.08rem solid #000;
  border-right: 0.08rem solid #000;
  .btnimg {
    width: 0.666667rem;
    height: 0.666667rem;
    vertical-align: middle;
    margin: 0 0.133333rem;
  }
}

.special-btn4 {
  width: 100%;
  // height: 1.28rem;
  // line-height: 1.28rem;
  margin-bottom: 0.373333rem !important;
  font-size: 0.373333rem;

  position: relative;
  z-index: 9999999999999999999999999999999;
  :root {
    --my-color: rgba(255, 21, 110, 1);
    --text-color: #000;
  }
  .btnimg {
    width: 0.666667rem;
    height: 0.666667rem;
    vertical-align: middle;
    margin: -0.106667rem 0.133333rem 0 0.133333rem;
  }
  &::after {
    content: "";
    width: 98%;
    border: 0.053333rem solid #5a5a5a;
    height: 85%;
    position: absolute;
    z-index: 0;
    background: var(--my-color);
    bottom: -0.13333rem;
    right: -0.13333rem;
  }
  div {
    border: 0.053333rem solid #5a5a5a;
    color: var(--text-color);
    position: relative;
    z-index: 66;
    padding: 0.266667rem 0.066667rem;
    background: #ffffff;
  }
}
.special-btn5 {
  margin-bottom: 0.373333rem !important;
  box-shadow: 0 0.08rem 0.32rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.266667rem;
  position: relative;
  z-index: 9999999999999999999999999999999;
  background-color: #fff;
  .item {
    border: 0.053333rem dashed #358690;
    padding: 0.266667rem 0.066667rem;
  }

  .btnimg1 {
    width: 0.4rem;
    height: 0.306667rem;
    margin-top: 0px !important;
    vertical-align: middle;
    margin-right: 0.133333rem;
  }
}
.wu {
  width: 0.893333rem !important;
  padding: 0.32rem 0 !important;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 0.066667rem;
}
.special-btn6 {
  z-index: 9999999999999999999999999999999;
  margin-bottom: 0.373333rem !important;
  padding: 0.453333rem;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 0.106667rem;
  .btnimg1 {
    width: 0.4rem;
    height: 0.306667rem;
    vertical-align: middle;
    margin-top: 0px !important;
  }
}

.left {
  text-align: left;
}
.right {
  text-align: right;
}
.linkfrom {
  padding: 0 10px;
}
.btnimg1 {
  width: 0.4rem !important;
  height: 0.4rem !important;
  // margin-top: 0.133333rem;
  vertical-align: middle;
  margin: 0.133333rem;
}
.btnimg2 {
  width: 0.933333rem !important;
  height: 0.933333rem !important;
  vertical-align: middle;
  // margin-right: 0.133333rem;
}
.imgleft {
  float: left;
  // margin-top: 5px;
}
.imgright {
  float: right;
  // margin-top: 5px;
}
.block {
  margin-top: 10px;
  display: block;
  width: 100%;
}
.wuwidth {
  height: 0.8rem;
  width: 0.8rem;
}
.transapre {
  background: transparent !important;
}
.rabiorder {
  border-radius: 0.266667rem;
}
.line {
  line-height: 0.666667rem;
}
.bigwu1 {
  width: 0.893333rem;
}
.bigwu3 {
  width: 0.893333rem;
}
.bigwu2 {
  width: 0.893333rem;
  margin-right: 0.266667rem !important;
}
.bigwu5 {
  width: 0.893333rem;
  margin-right: 0.266667rem !important;
}
.btnimg4 {
  width: 0.933333rem;
  height: 0.933333rem;
}
.special-btn7 {
  z-index: 9999999999999999999999999999999;
  margin-bottom: 0.373333rem !important;
  padding: 0.253333rem;
  width: 95%;
  margin-left: 5%;
  position: relative;
  border: 0.053333rem solid #d249e1;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: -0.2rem;
    height: 100%;
    bottom: -0.2rem;
    border-bottom: 0.053333rem solid #d249e1;
    border-left: 0.053333rem solid #d249e1;
  }
  &::before {
    content: "";
    position: absolute;
    width: 0.25rem;
    left: -0.23rem;
    top: 0.04rem;
    transform: rotate(-52deg);
    border-top: 0.053333rem solid #d249e1;
  }
  .rightboder {
    position: absolute;
    width: 0.26rem;
    border-radius: 0.27rem;
    right: -0.079rem;
    bottom: -0.133rem;
    transform: rotate(-38deg);
    border-top: 0.053333rem solid #d249e1;
  }
}
</style>
