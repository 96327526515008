<template>
  <div class="iphoneinfo" :style="iphoneinfostyle">
    <video
      style="top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-size: cover;
     background-size: cover;
     background-repeat: no-repeat;width:100%;height:100%;
     background-position: center center;
     object-fit: cover;
     object-position: 50% 50%;"
      autoplay=""
      muted=""
      loop=""
      playsinline=""
      preload="metadata"
      v-if="data1.text_video !== ''"
    >
      <source :src="data1.text_video" type="video/mp4" />
    </video>

    <div class="iphoneinfo1">
      <div style="position: relative;z-index: 9;" class="avat">
        <el-image
          @click="jb()"
          class="caozuo-img"
          :src="require('@/util/images/user/jb.png')"
          fit="contain"
        ></el-image>
        <el-image
          @click="erwm"
          class="caozuo-img"
          :src="require('@/util/images/user/erwm.png')"
          fit="contain"
        ></el-image>
        <el-image
          class="caozuo-img"
          @click="erwm2"
          :src="require('@/util/images/user/tu.png')"
          fit="contain"
        ></el-image>
        <div class="avater" v-if="data1.approve != null && data1 != null">
          <a :href="'http://' + data1.portrait_url" target="_blank">
            <el-image class="nameimg" :src="data1.portrait" fit="contain">
              <div slot="error" class="image-slot">
                <el-image
                  class="nameimg"
                  :src="require('@/util/images/logop.png')"
                  fit="contain"
                >
                </el-image>
              </div>
            </el-image>
          </a>
          <!-- v-if="data1.approve" -->
          <el-image
            v-if="data1.approve == 1"
            class="vip"
            :src="require('@/util/images/user/vip.png')"
            fit="contain"
          >
          </el-image>
        </div>

        <el-image
          v-if="!iscang"
          class="caozuo-img"
          @click="shocang"
          :src="require('@/util/images/user/icon_gz.png')"
          fit="contain"
        ></el-image>
        <el-image
          v-if="iscang"
          class="caozuo-img"
          @click="shocang"
          :src="require('@/util/images/user/xinselect.png')"
          fit="contain"
        ></el-image>
        <el-image
          @click="dianzan"
          v-if="!islike"
          class="caozuo-img"
          :src="require('@/util/images/user/icon_dz.png')"
          fit="contain"
        ></el-image>
        <el-image
          @click="dianzan"
          v-if="islike"
          class="caozuo-img"
          :src="require('@/util/images/user/dianselelct.png')"
          fit="contain"
        ></el-image>
        <!-- @click="showurl" -->
        <el-image
          @click="onSelect"
          class="caozuo-img"
          :src="require('@/util/images/user/icon_fx.png')"
          fit="contain"
        ></el-image>
      </div>
      <div
        v-if="data1 != null || data1.datum_title != null"
        :class="{
          mini: data1.font_size == 'mini',
          smell: data1.font_size == 'smell',
          big: data1.font_size == 'big'
        }"
        :style="{ color: data1.datum_hue }"
      >
        {{ data1.datum_title }}
      </div>
      <div
        v-if="data1 != null || data1.position != null"
        :class="{
          mini: data1.font_size == 'mini',
          smell: data1.font_size == 'smell',
          big: data1.font_size == 'big'
        }"
        :style="{ color: data1.position_hue }"
      >
        {{ data1.position }}
      </div>

      <div class="conterinfo">
        <component
          v-if="item.status === 'normal'"
          :layoutbg="data1"
          :is="item.text_type"
          :dataitem="item"
          v-for="(item, index) in cominfolist"
          :key="index"
        ></component>
      </div>
    </div>

    <!-- <el-image
      :class="{ erwimg: true, hide: showewm, show: !showewm }"
      :src="erwm1"
      fit="contain"
    ></el-image> -->
    <el-image
      class="logo"
      @click="goback"
      v-if="data1.loginimage === ''"
      style="height:.613333rem;width:4rem"
      :src="require('@/util/images/index/pic_logo_head.png')"
      fit="contain"
    ></el-image>

    <el-image
      class="logo"
      @click="goback"
      v-if="data1.loginimage !== ''"
      style="height:.613333rem;width:4rem"
      :src="data1.loginimage"
      fit="contain"
    ></el-image>

    <popup round v-model="show">
      <div class="jb">
        <div class="title">举报</div>
        <el-input type="textarea" v-model="ruleForm.memo"></el-input>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </div>
    </popup>
    <popup round v-model="show1">
      <img
        :src="erwm1"
        style="  position: relative;
   z-index: 999999999999999999999999999999999999999999999999999999999999999999;"
      />
    </popup>
    <popup round v-model="show2" position="bottom">
      <el-form
        :model="nameForm"
        class="iphonefrom"
        label-position="left"
        ref="nameForm"
      >
        <el-form-item>
          <div class="iphonetitle">手机号快速登入</div>
        </el-form-item>
        <el-form-item prop="mobile" label="手机号:">
          <el-input
            :rules="[
              {
                required: true,
                message: '请输入手机号',
                trigger: 'blur'
              }
            ]"
            v-model="nameForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="captcha">
          <el-input
            type="number"
            :rules="[
              {
                required: true,
                message: '请输入验证码',
                trigger: 'blur'
              }
            ]"
            v-model="nameForm.captcha"
            placeholder="请输入验证码"
          >
            <template slot="append">
              <span v-show="showcount" @click="getCode">获取验证码</span>
              <span v-show="!showcount" class="count"
                >{{ count }} s</span
              ></template
            ></el-input
          >
        </el-form-item>
        <el-form-item>
          <el-button
            class="iphnoebtn"
            type="primary"
            @click="verificationsubmit('nameForm')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </popup>
    <!-- <popup round v-model="show3" position="bottom">
      <div class="show3">
       <div>
          这是来自于{{ data1.nickname }}的分享网址，
          {{ localurl }}
        </div>
 <button @click="copy()">点击复制分享</button>
        <div class="sharelist">
          <div class="shareitem" @click="onSelect()">
            <el-image
              class="shareimg"
              :src="require('../../util/images/weixinshare.png')"
              fit="contain"
            ></el-image>
            <div class="sharetext">
              分享到朋友圈
            </div>
          </div>
          <div class="shareitem" @click="onSelect('qZone')">
            <el-image
              class="shareimg"
              :src="require('../../util/images/qqshare.png')"
              fit="contain"
            ></el-image>
            <div class="sharetext">
              分享到QQ空间
            </div>
          </div>
        </div>
      </div>
    </popup> -->
    <popup round v-model="show5">
      <div
        style="position: relative;z-index: 999999999999999999999999999999999999999999999999999999999999999999;width:8rem;height:15rem"
      >
        <div
          class="title"
          style="height:.7rem;line-height:.7rem;font-weight:bold;color:#fff;background:black"
        >
          请长按图片保存到手机
        </div>
        <img :src="erwm1" style="width:100%" />
      </div>
    </popup>
    <popup round v-model="show3">
      <div class="show3" style="padding:.4rem">
        <div style="background:#f5f5f5;padding:.4rem;margin-bottom:.4rem">
          这是来自于{{ data1.nickname }}的 分享网址，
          {{ localurl }}
        </div>
        <button
          @click="copy()"
          style="background: linear-gradient(
135deg, #2ec2ff 0%, #0383e4 100%);"
        >
          点击复制分享
        </button>
      </div>
    </popup>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import "vant/lib/toast/style";
import toast from "vant/lib/toast/index";
import "vant/lib/popup/style";
import popup from "vant/lib/popup/index";
import { iphonehsend, hsmscheck } from "../../api/userapi";
import {
  layoutbg,
  relevance,
  report,
  qrcode,
  hphone,
  favoritelist,
  praise,
  favorite,
  stationery,
  hiabao
} from "../../api/iphone";
import linker from "../textshow/index.vue";
import iphoneBtn from "../iphonebtn/index.vue";
import fromSumbit from "../fromsumbit/index.vue";
import swiper from "../swiper/index.vue";
import iphoneVideo from "../iphonevideo/index.vue";
import iphoneimage from "../iphoneimage/index.vue";
import tableinfo from "../tableinfo/index.vue";
import iphoneemail from "../iphoneemail/index.vue";
import iphoneshop from "../iphoneshop/index.vue";
import NativeShare from "nativeshare";
// import { set } from "vue/types/umd";
const wx = require("weixin-js-sdk");
export default {
  name: "iphoneinfo",

  components: {
    linker,
    iphoneBtn,
    fromSumbit,
    swiper,
    iphoneVideo,
    iphoneimage,
    tableinfo,
    iphoneemail,
    iphoneshop,
    popup
  },

  data() {
    return {
      showewm: false,
      iphoneinfostyle: { background: "" },
      nameForm: { mobile: "", event: "手机号登入", captcha: "" },
      showcount: true,
      count: 0,
      show1: false,
      show: false,
      show3: false,
      cominfolist: "",
      data1: {},
      userid: "",
      erwm1: "",
      lookid: null,
      ruleForm: {
        memo: ""
      },
      localurl: "",
      islike: false,
      iscang: false,
      show2: false,
      show5: false,
      shlist: {},
      showtype: 1,
      title: "dddddddfs",
      longClick: 0,
      timeOutEvent: 0
    };
  },
  created() {
    if (window.localStorage.getItem("infortree-lookid") !== undefined) {
      this.lookid = window.localStorage.getItem("infortree-lookid");
    }
    if (this.$route.query.userid !== undefined) {
      this.userid = window.atob(this.$route.query.userid);
      return;
    }

    if (window.localStorage.getItem("infortree-userid") !== undefined) {
      this.userid = window.localStorage.getItem("infortree-userid");
    }
  },
  mounted() {
    // this.getbtninfo();
    this.getinfolist();
    if (this.lookid != null) {
      this.getlookinfo();
    }
    document.querySelector("body").setAttribute("id", "main");
  },
  methods: {
    showurl() {
      this.show3 = true;
    },
    // 长按
    start() {
      var that = this;
      this.longClick = 0;
      this.timeOutEvent = setTimeout(function() {
        that.longClick = 1;
        that.downImg();
      }, 500);
    },
    // 下载
    downImg() {
      if (!window.plus) return;
      plus.gallery.save(
        this.erwm1,
        function() {
          // alert('保存成功!');
        },
        function() {
          // alert('保存失败，请重试!');
        }
      );
    },
    getshareinfo() {
      stationery().then(res => {
        console.log(res);
        this.shlist = res.list;
      });
    },
    onSelect(sharetype) {
      const self = this;
      var ua = window.navigator.userAgent.toLowerCase();

      var data = {
        url: window.location.href.split("#")[0]
      };
      stationery(data).then(res => {
        this.shlist = res.list;

        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          alert("请点击右上角三个点分享链接");
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: self.shlist.appId,
            timestamp: self.shlist.timestamp,
            nonceStr: self.shlist.nonceStr,
            signature: self.shlist.signature,
            jsApiList: [
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "onMenuShareQQ",
              "onMenuShareWeibo"
            ] // 必填，需要使用的JS接口列表
          });
          wx.ready(function() {
            // 分享给朋友
            wx.onMenuShareAppMessage({
              title: "这是来自于" + self.data1.nickname + "的分享网址",
              desc: "这是来自于" + self.data1.nickname + "的分享网址",
              link: window.location.href,
              imgUrl:
                "https://www.infortree.com/uploads/image/20211028/67a01a1cf7a1097f4e6bb0913458d349.jpg",
              success: function(res) {
                alert("分享成功！");
              },
              cancel: function(res) {
                alert("分享失败！");
              }
            });
            wx.onMenuShareQQ({
              title: "这是来自于" + self.data1.nickname + "的分享网址",
              desc: "这是来自于" + self.data1.nickname + "的分享网址",
              link: window.location.href,
              imgUrl:
                "https://www.infortree.com/uploads/image/20211028/67a01a1cf7a1097f4e6bb0913458d349.jpg",
              trigger: function trigger(res) {
                // alert('用户点击分享到QQ');
              },
              complete: function complete(res) {
                alert(JSON.stringify(res));
              },
              success: function success(res) {
                // alert('已分享');
              },
              cancel: function cancel(res) {
                // alert('已取消');
              },
              fail: function fail(res) {
                // alert(JSON.stringify(res));
              }
            });
            wx.onMenuShareWeibo({
              title: "这是来自于" + self.data1.nickname + "的分享网址",
              desc: "这是来自于" + self.data1.nickname + "的分享网址",
              link: window.location.href,
              imgUrl:
                "https://www.infortree.com/uploads/image/20211028/67a01a1cf7a1097f4e6bb0913458d349.jpg",
              trigger: function trigger(res) {
                alert("用户点击分享到微博");
              },
              complete: function complete(res) {
                alert(JSON.stringify(res));
              },
              success: function success(res) {
                alert("已分享");
              },
              cancel: function cancel(res) {
                alert("已取消");
              },
              fail: function fail(res) {
                alert(JSON.stringify(res));
              }
            });

            // 分享到朋友圈
            wx.onMenuShareTimeline({
              title: "这是来自于" + self.data1.nickname + "的分享网址",
              link: window.location.href,
              imgUrl:
                "https://www.infortree.com/uploads/image/20211028/67a01a1cf7a1097f4e6bb0913458d349.jpg",
              success: function(res) {
                alert("分享成功！");
              },
              cancel: function(res) {
                alert("分享失败！");
              }
            });
          });
          return;
        }

        var nativeShare = new NativeShare({
          wechatConfig: {
            appId: self.shlist.appId,
            timestamp: self.shlist.timestamp,
            nonceStr: self.shlist.nonceStr,
            signature: self.shlist.signature
          },
          // 让你修改的分享的文案同步到标签里，比如title文案会同步到<title>标签中
          // 这样可以让一些不支持分享的浏览器也能修改部分文案，默认都不会同步
          syncDescToTag: false,
          syncIconToTag: false,
          syncTitleToTag: false
        });

        // 设置分享文案
        nativeShare.setShareData({
          icon:
            "https://www.infortree.com/uploads/image/20211028/67a01a1cf7a1097f4e6bb0913458d349.jpg",
          link: window.location.href,
          title: "这是来自于" + self.data1.nickname + "的分享网址",
          desc: "这是来自于" + self.data1.nickname + "的分享网址",
          from: "@fa-ge"
        });
        try {
          nativeShare.call();
          // 如果是分享到微信则需要
          // 类似的命令下面有介绍
        } catch (err) {
          // 如果不支持，你可以在这里做降级处理
          if (self.showtype !== 1) {
            self.copy();
          } else {
            self.showtype = 2;
          }
        }
      });
    },

    download() {
      this.showewm = false;
      setTimeout(() => {
        html2canvas(document.getElementById("main"), {
          logging: false,
          useCORS: true,
          allowTaint: false,
          proxy: "https://www.infortree.com/"
        })
          .then(canvas => {
            const link = document.createElement("a");
            link.href = canvas.toDataURL(); // 下载链接
            link.setAttribute("download", "海报.png");
            link.style.display = "none"; // a标签隐藏
            document.body.appendChild(link);
            link.click();
            this.showewm = true;
          })
          .catch(res => {
            this.showewm = true;
          });
      }, 2000);
    },
    goback() {
      window.open("https://infortree.com/Html/index.html#/", "_blank");
    },
    copy() {
      this.copyData =
        "这是来自于" + this.data1.nickname + "的分享网址，" + this.localurl;
      var oInput = document.createElement("input");
      oInput.value = this.copyData;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message.success("复制分享链接成功！");
    },

    dianzan() {
      if (this.lookid === null) {
        this.unlogin();
        return;
      }
      praise({ user_id: this.userid, meber_id: this.lookid }).then(res => {
        if (res.code === 1) {
          this.getlookinfo();
        }
      });
    },
    shocang() {
      if (this.lookid === null) {
        this.unlogin();
        return;
      }
      favorite({ user_id: this.userid, meber_id: this.lookid }).then(res => {
        if (res.code === 1) {
          this.getlookinfo();
        }
      });
    },
    // 获取点赞信息
    getlookinfo() {
      favoritelist({
        user_id: this.userid,
        meber_id: this.lookid,
        type: 1
      }).then(res => {
        if (res.code === 1) {
          this.iscang = true;
        } else if (res.code === 400) {
          this.iscang = false;
        }
      });
      favoritelist({
        user_id: this.userid,
        meber_id: this.lookid,
        type: 2
      }).then(res => {
        if (res.code === 1) {
          this.islike = true;
        } else if (res.code === 400) {
          this.islike = false;
        }
      });
    },
    // 下一步加提交验证码
    verificationsubmit(formName) {
      const that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          hsmscheck(this.nameForm).then(res => {
            if (res.code === 1) {
              that.$message.success(res.msg);
              hphone({ phone: that.nameForm.mobile }).then(res => {
                if (res.code === 1) {
                  window.localStorage.setItem("infortree-lookid", res.list.id);
                  that.lookid = window.localStorage.getItem("infortree-lookid");
                  that.$message.success("登入成功");
                  that.getlookinfo();
                  that.show2 = false;
                } else {
                  that.$message.error(res.msg);
                }
              });
            } else {
              that.$message.error(res.msg);
            }
          });
        } else {
          that.$message.error("提交错误请重新提交");
          return false;
        }
      });
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showcount = false;
        this.sendcode();
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showcount = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 发送验证码
    sendcode() {
      var that = this;
      iphonehsend({
        mobile: that.nameForm.mobile,
        event: "手机号登入"
      }).then(res => {
        if (res.code === 1) {
          that.$message.success(res.msg);
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    erwm() {
      qrcode({ user_id: this.userid }).then(res => {
        this.erwm1 = res;
      });
      this.show1 = true;
    },
    erwm2() {
      hiabao({ user_id: this.userid }).then(res => {
        this.erwm1 = res.list;
        this.show5 = true;
      });
    },
    onSubmit() {
      report({
        user_id: this.userid,
        memo: this.ruleForm.memo,
        member_id: this.lookid
      }).then(res => {
        if (res.code === 1) {
          this.show = false;
          toast(res.msg);
        } else {
          toast(res.msg);
        }
      });
    },
    // 举报
    jb() {
      if (this.lookid === null) {
        this.show2 = true;
      } else {
        this.show = true;
      }
    },
    unlogin() {
      if (this.lookid === null) {
        this.show2 = true;
      }
    },
    getinfolist() {
      relevance({ user_id: this.userid }).then(res => {
        this.cominfolist = res.list;
        this.getbtninfo();
      });
    },
    getbtninfo() {
      let background = "";
      layoutbg({ user_id: this.userid }).then(res => {
        if (res.code === 1) {
          if (res.layout_bg != null) {
            this.data1 = res.layout_bg;
            this.localurl =
              "http://" + this.data1.user.username + ".infortree.com/";
            var link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = this.data1.favicon_image;
            document.getElementsByTagName("head")[0].appendChild(link);
            document.title = this.data1.webite_title;
            if (this.$route.query.userid !== undefined) {
              this.data1.text_color !== ""
                ? (background = "background:" + this.data1.text_color + " ;")
                : (background =
                    "background:url(" +
                    this.data1.text_img +
                    ")  !important;background-size: 100% 100% !important;background-repeat: no-repeat !important;");
              this.data1.text_img !== ""
                ? (background =
                    "background:url(" +
                    this.data1.text_img +
                    ");background-size: 100% 100% !important;background-repeat: no-repeat !important;")
                : (background = "background:" + this.data1.text_color + ";");
              document.querySelector("body").setAttribute("style", background);
            } else {
              this.data1.text_color !== ""
                ? (background = "background:" + this.data1.text_color + " ;")
                : (background =
                    "background:url(" +
                    this.data1.text_img +
                    ");background-size: 100% 100% !important;background-repeat: no-repeat !important;");
              this.data1.text_img !== ""
                ? (background =
                    "background:url(" +
                    this.data1.text_img +
                    ");background-size: 100% 100% !important;background-repeat: no-repeat !important;")
                : (background = "background:" + this.data1.text_color + ";");
              this.iphoneinfostyle = background;
            }
          } else {
            this.data1.text_color = null;
            this.data1.text_img = null;
          }
        }
      });
    }
  }
};
</script>
<style lang="less">
.sharelist {
  height: 3.666667rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .shareitem {
    height: 2.666667rem;
    width: 50%;
    .shareimg {
      height: 2rem;
      line-height: 2rem;
    }
  }
}
/deep/.van-popup {
  z-index: 99999999999999999999999999999999999999999999999999999999999999999999999999999999999 !important;
}
/deep/.van-overlay {
  z-index: 99999999999999999999999999999999999999999999999999999999999999999999999999999999999 !important;
}

/deep/body {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  // background-attachment: fixed;
  min-height: 100%;
}
html,
body {
  height: 100%;
  overflow: auto;
}
#app {
  height: 100%;
  overflow: auto;
}
</style>
<style lang="less" scoped>
.institle {
  padding: 30px;
}

.iphoneinfo {
  background-size: 100% 100% !important;
  box-sizing: border-box;
}
.erwimg {
  position: absolute;
  bottom: 1.666667rem;
  font-size: 0.32rem;
  left: 0;
  right: 0;
  margin: auto;
  color: #ffffff;
  width: 2.5rem;
  height: 2.5rem;
}
.hide {
  display: none;
}
.show {
  display: inline-block;
}
.logo {
  position: absolute;
  bottom: 0.666667rem;
  font-size: 0.32rem;
  left: 0;
  right: 0;
  margin: auto;
  color: #ffffff;
}
/deep/.iphnoebtn {
  width: 100%;
  background-image: linear-gradient(90deg, #0facf3, #4259ff);
}
.iphonetitle {
  font-size: 0.4rem;
  font-weight: 800;
  line-height: 0.8rem;
  text-align: center;
}
.iphonefrom {
  width: 100%;
  padding: 0.4rem;
  position: relative;
  z-index: 999999999999999999999999999999999999999999999999999999999999999999;
}
.avat {
  display: flex;
  justify-content: space-around;
  .caozuo-img {
    width: 0.813333rem;
    height: 0.813333rem;
    border-radius: 0.613333rem;
    margin-left: 0.173333rem;
  }
  .avater {
    .nameimg {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }
    .vip {
      position: absolute;
      bottom: 0;
      left: 1.266667rem;
      margin: auto;
      width: 0.453333rem;
      height: 0.453333rem;
      border-radius: 0.453333rem;
      right: 0;
    }
  }
}
.jb {
  padding: 0.666667rem 0.266667rem;
  width: 8rem;
  position: relative;
  z-index: 999999999999999999999999999999999999999999999999999999999999999999;
  // height:5rem;
  .title {
    font-size: 0.426667rem;
    text-align: center;
    font-weight: 800;
    margin: 0 0 0.4rem 0;
  }
  .el-textarea {
    margin-bottom: 0.4rem;
  }
}

.conterinfo {
  padding-top: 0.4rem;
  // .iphonebtn {
  //  display: inline-block;
  // }
}
a {
  display: inline-block;
}
.iphoneinfo {
  overflow: hidden;
  position: relative;
  max-width: 100%;
  padding-bottom: 4.333333rem !important;
  min-height: 100%;
}
video {
  max-width: 100%;
  position: absolute;
}
.caozuo {
  position: absolute;
  top: 0.16rem;
  left: 0px;
  justify-content: space-between;
  display: flex;
  padding: 0.666667rem 0.266667rem 0 0.266667rem;
  width: 100%;
  div {
    display: inline-block;
  }
  .caozuo-img {
    width: 0.613333rem;
    height: 0.613333rem;
    border-radius: 0.613333rem;
    margin-left: 0.373333rem;
  }
}
body {
  // overflow-x: hidden;
  // background-image: url(img/premium16.jpg) !important;
  background-size: cover !important;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  font-family: Impact, Charcoal, sans-serif !important;
}

.iphoneinfo {
  text-align: center;
  padding: 0.666667rem 0.586667rem;
}
</style>
