<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-form-item prop="member_name">
        <el-input
          v-model="ruleForm.member_name"
          placeholder="您的名字"
        ></el-input>
      </el-form-item>
      <el-form-item prop="member_email">
        <el-input
          v-model="ruleForm.member_email"
          placeholder="您的邮箱"
        ></el-input>
      </el-form-item>
      <el-form-item prop="member_tel">
        <el-input
          v-model="ruleForm.member_tel"
          placeholder="您的电话"
        ></el-input>
      </el-form-item>
      <el-form-item prop="member_info">
        <el-input
          type="textarea"
          v-model="ruleForm.member_info"
          placeholder="请输入信息"
        ></el-input>
      </el-form-item>
      <el-form-item prop="member_info">
        <div style="display:flex;align-items: center;" class="boxcheck">
          <el-checkbox-group v-model="type">
            <el-checkbox name="type"></el-checkbox>
          </el-checkbox-group>
          <span :style="{ color: dataitem.text_color }"
            >我确认提交的内容可存储于网站以通知对方响应我的请求。并符合隐私保护条例。</span
          >
        </div>
      </el-form-item>
      <el-form-item>
        <div
          class="btn"
          :style="{
            color: dataitem.text_color,
            borderColor: layoutbg.datum_hue
          }"
          @click="onSubmit('ruleForm')"
        >
          发送
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { inboxadd } from "../../api/iphone";
export default {
  name: "WorkspaceJsonIndex",
  props: { layoutbg: { type: Object }, dataitem: { type: Object } },
  components: {},

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else {
        const reg = new RegExp(
          "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
        );
        const ismail = reg.test(value);
        if (!ismail) {
          callback(new Error("邮箱格式不正确！"));
        } else {
          callback();
        }
      }
    };
    var validatemobli = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        const ismail = reg.test(value);
        if (!ismail) {
          callback(new Error("手机号格式不正确！"));
        } else {
          callback();
        }
      }
    };

    return {
      ruleForm: {
        member_name: "",
        member_tel: "",
        member_email: "",
        member_info: "",
        user_id: ""
      },
      type: true,
      rules: {
        member_name: [
          { required: true, message: "请输入姓名", trigger: "blur" }
        ],
        member_email: [{ validator: validatePass, trigger: "blur" }],
        member_tel: [{ validator: validatemobli, trigger: "blur" }]
      }
    };
  },

  mounted() {
    this.color = this.layoutbg.datum_hue;
  },

  methods: {
    onSubmit(formName) {
      if (!this.type) {
        return this.$message.error("请选择协议");
      }
      this.ruleForm.user_id = this.dataitem.user_id;
      this.$refs[formName].validate(valid => {
        if (valid) {
          debugger;
          inboxadd(this.ruleForm).then(res => {
            if (res.code === 1) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.btn {
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  border-radius: 0.293333rem;
  border: 0.026667rem solid;
  font-size: 0.346667rem;
  background: transparent;
}
</style>
