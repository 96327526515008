<template>
  <div class="list">
    <div
      class="shopitem"
      @click="show(item)"
      v-for="(item, index) in dataitem.test_image"
      :key="index"
    >
      <el-image :src="item.image" fit="contain"></el-image>
      <div class="shoptitle">{{ item.title }}</div>
    </div>
    <popup @close="clear" position="top" round v-model="shopshow">
      <div style="height:1rem">
        <i
          @click="clear"
          style="float:right;margin:.4rem .4rem .4rem 0"
          class="el-icon-close"
        ></i>
      </div>
      <div class="shopinfo" v-if="lookcat1">
        <el-image
          style="border-radius:.4rem;"
          :src="shopinfo.image"
          fit="contain"
        ></el-image>
        <div class="shoptitle" style=" text-align: left;">
          {{ shopinfo.title }}
        </div>
        <div class="shopinfo1" style=" text-align: left;">
          {{ shopinfo.content }}
        </div>
        <div class="money" style=" text-align: left;">
          {{ shopinfo.price }}
        </div>
        <div class="btn">
          <el-button @click="addcat">加入购物车</el-button>
          <el-button @click="lookcat">查看购物车</el-button>
        </div>
      </div>
      <div class="catinfo" v-if="!lookcat1">
        <el-form
          v-if="showsubmit"
          :model="from"
          label-position="top"
          class="demo-form-inline"
        >
          <el-form-item label="姓名：">
            <el-input v-model="from.name" placeholder="请填写姓名"></el-input>
          </el-form-item>
          <el-form-item label="邮箱：">
            <el-input v-model="from.email" placeholder="请填写邮箱"></el-input>
          </el-form-item>
          <el-form-item label="电话：">
            <el-input v-model="from.phone" placeholder="请填写电话"></el-input>
          </el-form-item>
        </el-form>
        <div class="list">
          <div class="listitem" v-for="item in shoplist" :key="item.gid">
            <div class="numlist2">
              <el-image
                @click="edit(item)"
                style="height: 1.333333rem;vertical-align:middle;margin:0 10px;width:1.333333rem"
                :src="item.image"
                fit="contain"
              ></el-image
              >{{ item.title }}
            </div>
            <div class="numlist">
              <i
                style="text-align:right"
                class="el-icon-delete"
                @click="delete1(item.gid)"
              ></i>
              <div class="numlist-item">
                <i class="el-icon-circle-plus add" @click="add(item.gid)"></i>
                <input class="num" type="number" v-model="item.num" />
                <i class="el-icon-remove add" @click="jian(item.gid)"></i>
              </div>
            </div>
          </div>
          <div class="total">
            总价：<span style="color：red">{{ total }}</span>
          </div>
        </div>
        <div class="btn">
          <el-button v-if="!showsubmit" @click="submit">提交订单</el-button>
          <el-button v-if="showsubmit" @click="submit1">提交订单</el-button>
          <el-button @click="clear()">继续购物</el-button>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import "vant/lib/popup/style";
import popup from "vant/lib/popup/index";
import { orderadd } from "../../api/iphone";
export default {
  name: "shoplist",
  components: { popup },
  props: { dataitem: { type: Object } },

  data() {
    return {
      lookcat1: true,
      total: 0,
      from: {
        name: "",
        email: "",
        phone: "",
        goods_title: "",
        goods_id: "",
        total_price: "",
        user_id: ""
      },
      showsubmit: false,
      shoplist: [],
      shopshow: false,
      shopinfo: ""
    };
  },

  mounted() {
    this.from.user_id = this.dataitem.user_id;
    // this.dataitem.test_image = this.dataitem.test_image.replace(/&quot;/g, '"');
    this.dataitem.test_image = JSON.parse(this.dataitem.test_image);
  },
  methods: {
    clear() {
      this.shopshow = false;
      this.showsubmit = false;
      this.lookcat1 = true;
      this.shopshow = false;
      this.from = {
        name: "",
        email: "",
        phone: "",
        goods_title: "",
        goods_id: "",
        total_price: "",
        user_id: this.dataitem.user_id
      };
    },
    submit1() {
      const that = this;
      that.shoplist.forEach(res => {
        var title = "";
        title = res.title + "/" + res.num + ",";
        that.from.goods_title += title;
        that.from.goods_id += res.gid + ",";
      });
      that.from.goods_title = that.from.goods_title.slice(
        0,
        that.from.goods_title.length - 1
      );
      that.from.goods_id = that.from.goods_id.slice(
        0,
        that.from.goods_id.length - 1
      );
      that.from.total_price = that.total;
      if (that.from.name.length <= 0) {
        return this.$message.error("请填写姓名");
      }
      if (that.from.email.length <= 0) {
        return this.$message.error("请填写邮件");
      }
      if (that.from.phone.length <= 0) {
        return this.$message.error("请填写电话");
      }
      orderadd(this.from).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg);
          this.clear();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    lookcat() {
      this.lookcat1 = false;
    },
    submit() {
      this.showsubmit = true;
    },
    // 总价
    zong() {
      const that = this;
      var total = 0;
      that.shoplist.forEach(res => {
        var price = Number(res.price) * Number(res.num);
        total += price;
      });
      that.total = total.toFixed();
      that.$forceUpdate();
    },
    jian(id) {
      const that = this;
      var index = that.shoplist.findIndex(res => {
        return res.gid === id;
      });
      if (that.shoplist[index].num <= 1) {
        that.shoplist[index].num = 1;
      } else {
        that.shoplist[index].num -= 1;
      }
      that.zong();
      that.$forceUpdate();
    },
    add(id) {
      const that = this;
      var index = that.shoplist.findIndex(res => {
        return res.gid === id;
      });
      that.shoplist[index].num += 1;
      that.zong();
      that.$forceUpdate();
    },
    delete1(id) {
      const that = this;
      var index = that.shoplist.findIndex(res => {
        return res.gid === id;
      });
      delete that.shoplist[index];
      that.zong();
      that.$forceUpdate();
    },
    addcat() {
      this.shopinfo = JSON.stringify(this.shopinfo);
      this.shopinfo = JSON.parse(this.shopinfo);
      this.shopinfo.num = 1;
      const that = this;
      var index = that.shoplist.findIndex(res => {
        return res.gid === that.shopinfo.gid;
      });
      if (index === -1) {
        this.shoplist.push(that.shopinfo);
      } else {
        this.shoplist[index].num += 1;
      }
      this.zong();
    },
    show(item) {
      this.shopinfo = item;
      this.shopshow = true;
    }
  }
};
</script>

<style lang="less" scoped>
.add {
  width: 0.533333rem;
  height: 0.533333rem;
  font-size: 0.533333rem;
}
.numlist {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.numlist-item {
  display: flex;
  -webkit-align-items: center;
  display: -webkit-box-flex;
  align-items: center;
}
.num {
  width: 0.666667rem;
  height: 0.533333rem;
  text-align: center;
  border: 1px solid #eee;
  margin: 0 0.133333rem;
}
.listitem {
  height: 1.333333rem;
  margin-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.266667rem;
  display: -webkit-box-flex;
  -webkit-justify-content: space-between;
}
.numlist2 {
  display: flex;
  -webkit-align-items: center;
  display: -webkit-box-flex;
  align-items: center;
  width: 70%;
}
.shopinfo {
  padding: 0.4rem;
}
.shoptitle {
  font-size: 0.266667rem;
  font-weight: 700;
  line-height: 0.666667rem;
}
.shopinfo1 {
  font-size: 0.24rem;
  line-height: 0.32rem;
  margin-bottom: 0.266667rem;
}
.btn {
  display: flex;
  justify-content: space-between;
  .el-button {
    border: 0px;
    width: 45%;
    color: #fff;
    font-size: 0.32rem;
    background: #222;
  }
}
.money {
  font-size: 0.213333rem;
  color: red;
  margin-bottom: 0.133333rem;
}
.list {
  text-align: center;
  .shopitem {
    width: 46%;
    margin-right: 2%;
    display: inline-block;
    background: #fff;
    border-radius: 0.133333rem;
    margin-bottom: 0.133333rem;
    .el-image {
      max-width: 100%;
      border-radius: 0.133333rem 0.133333rem 0 0;
    }
    .shoptitle {
      font-size: 0.293333rem;
      font-weight: bold;
      margin: 0.266667rem 0;
    }
  }
}
.catinfo {
  padding: 0.4rem;
}
</style>
