<template>
  <div>
    <video
      oncontextmenu="return false;"
      controlslist="nodownload"
      controls=""
      poster=""
    >
      <source :src="dataitem.url" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "WorkspaceJsonIndex",
  props: { dataitem: { type: Object } },
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="less" scoped>
video {
  max-width: 100%;
  margin-bottom: 0.266667rem;
}
</style>
