<template>
  <div class="iphoneemail">
    <div
      @click="changeshow"
      :style="{ color: dataitem.text_color, background: dataitem.button_color }"
      :class="{ button: true, smell: true, 'flip-2-ver-right-bck': show }"
    >
      {{ dataitem.title }}
    </div>
    <div :class="{ submit: true, 'flip-2-ver-right-bck1': show }">
      <input type="text" v-model="email" placeholder="请输入您的邮箱" />
      <div
        :style="{
          color: dataitem.text_color,
          background: dataitem.button_color
        }"
        class="subtn smell"
        @click="cilicknumber"
      >
        订阅
      </div>
    </div>
  </div>
</template>

<script>
import { relevancedj, subscription } from "../../api/iphone";
export default {
  name: "iphoneemail",
  props: { layoutbg: { type: Object }, dataitem: { type: Object } },
  data() {
    return {
      show: false,
      email: ""
    };
  },

  mounted() {},

  methods: {
    changeshow() {
      this.show = true;
    },
    validatePass(value) {
      if (value === "") {
        return false;
      } else {
        const reg = new RegExp(
          "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
        );
        const ismail = reg.test(value);
        if (!ismail) {
          return false;
        } else {
          return true;
        }
      }
    },
    cilicknumber() {
      if (!this.validatePass(this.email)) {
        return this.$message.error("邮箱格式不正确！");
      }
      subscription({
        user_id: this.dataitem.user_id,
        email: this.email
      }).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
      relevancedj({
        layout_id: this.dataitem.id,
        meber_id: this.dataitem.user_id
      }).then(res => {
        if (res.code === 1) {
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.iphoneemail {
  margin-bottom: 0.4rem;
  position: relative;
}
.button {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 1.066667rem;
  border-radius: 0.333333rem;
  width: 100%;
  line-height: 1.066667rem;
}
.submit {
  display: flex;
  height: 1.066667rem;
  line-height: 1.066667rem;
  border-radius: 0.333333rem;
  input {
    width: 70%;
    border-radius: 0.333333rem 0 0 0.333333rem;
    border: 0px;
    padding: 0 0.133333rem;
  }
  .subtn {
    height: 100%;
    border-radius: 0 0.333333rem 0.333333rem 0;
    width: 30%;
    line-height: 1.066667rem;
  }
}
.flip-2-ver-right-bck {
  -webkit-animation: scale-down-hor-right 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-hor-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.flip-2-ver-right-bck1 {
  -webkit-animation: scale-down-hor-right1 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-hor-right1 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}
@-webkit-keyframes scale-down-hor-right1 {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
@keyframes scale-down-hor-right1 {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
@-webkit-keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    display: none;
  }
}
@keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    display: none;
  }
}
</style>
