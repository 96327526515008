<template>
  <div>
    <div class="textshow">
      <el-image
        v-if="dataitem.interlinkage_image != ''"
        :src="dataitem.interlinkage_image"
        fit="contain"
      ></el-image>
      <div
        @click="changeshow"
        v-show="index == 1"
        class="item h"
        :style="{ width: dataitem.interlinkage_image == '' ? '100%' : '85%' }"
        v-html="dataitem.info"
      ></div>
      <div
        @click="changeshow"
        v-show="index == 2"
        class="item"
        :style="{ width: dataitem.interlinkage_image == '' ? '100%' : '85%' }"
        v-html="dataitem.info"
      ></div>
    </div>
  </div>
</template>

<script>
import { relevancedj } from "../../api/iphone";
export default {
  name: "textshow",
  props: { dataitem: { type: Object } },
  data() {
    return {
      index: 1
    };
  },

  mounted() {},

  methods: {
    changeshow() {
      this.index === 1 ? (this.index = 2) : (this.index = 1);
      relevancedj({
        layout_id: this.dataitem.id,
        meber_id: this.dataitem.user_id
      }).then(res => {
        if (res.code === 1) {
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.textshow {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  margin: 0.266667rem 0;
  position: relative;
  z-index: 99;
  .el-image {
    width: 0.666667rem;
    height: 0.666667rem;
    margin-right: 0.266667rem;
  }
  .item {
    width: 85%;
    float: right;
    word-wrap: break-word;
    word-break: normal;
    word-break: break-all;
    // line-height: 0.733333rem;
    text-align: left;
  }
  .h {
    max-height: 1.02rem;
    font-size: 0.4rem;
    overflow: hidden;
    line-height: 0.5rem;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
/deep/p {
  font-size: 0.4rem;
}
/deep/pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
  font-size: 0.4rem;
}
</style>
