var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    iphonebtn: true,
    bigbtn: Number(_vm.dataitem.link_type) == 1,
    bighaf: Number(_vm.dataitem.link_type) == 2,
    bigsqua: Number(_vm.dataitem.link_type) == 3,
    bigwu: Number(_vm.dataitem.link_type) == 4,
    bigwu2:
      Number(_vm.dataitem.link_type) == 4 &&
      Number(_vm.layoutbg.button_type_id) == 9 &&
      _vm.dataitem.text_color == null,
    bigwu5:
      Number(_vm.dataitem.link_type) == 4 &&
      Number(_vm.layoutbg.button_type_id) != 9 &&
      _vm.dataitem.text_color == null,
    bigwu1:
      Number(_vm.dataitem.link_type) == 4 &&
      Number(_vm.layoutbg.button_type_id) == 12,
    bigwu3:
      Number(_vm.dataitem.link_type) == 4 && Number(_vm.layoutbg.button_type_id) == 9
  }},[(
      _vm.layoutbg.button_type_id === 'false' && Number(_vm.dataitem.link_type) != 4
    )?_c('div',{class:{
      btnitem: true,
      btn1: _vm.type1,
      btn2: _vm.layoutbg.button_circle === '1',
      btn3: _vm.layoutbg.button_outline === '1',
      btn4: _vm.layoutbg.button_social === '1',
      btn5: _vm.layoutbg.button_corner === '1',
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big',
      transapre: _vm.dataitem.button_type === 'Y'
    },style:({
      color: _vm.dataitem.text_color,
      background: _vm.dataitem.button_color,
      opacity: _vm.dataitem.opacity
    }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('el-image',{class:{
        btnimg: !_vm.type5,
        btnimg1: _vm.type5,
        btnimg2: _vm.dataitem.icon_wz == 3,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },attrs:{"src":_vm.dataitem.interlinkage_image}}):_vm._e(),(_vm.dataitem.title != '')?_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3,
        line: _vm.dataitem.interlinkage_image != ''
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))]):_vm._e()],1):_vm._e(),(_vm.type3)?_c('div',{class:{
      btnitem: true,
      btn1: _vm.type1,
      btn2: _vm.layoutbg.button_circle === '1',
      btn3: _vm.layoutbg.button_outline === '1',
      btn4: _vm.layoutbg.button_social === '1',
      btn5: _vm.layoutbg.button_corner === '1',
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big',
      transapre: _vm.dataitem.button_type === 'Y'
    },style:({
      color: _vm.dataitem.text_color,
      background: _vm.dataitem.button_color,
      opacity: _vm.dataitem.opacity
    }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('el-image',{class:{
        btnimg: !_vm.type5,
        btnimg1: _vm.type5,
        btnimg2: _vm.dataitem.icon_wz == 3,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },attrs:{"src":_vm.dataitem.interlinkage_image}}):_vm._e()],1):(_vm.type4)?_c('div',{class:{
      btnitem: true,
      btn1: _vm.type1,
      btn2: _vm.layoutbg.button_circle === '1',
      btn3: _vm.layoutbg.button_outline === '1',
      btn4: _vm.layoutbg.button_social === '1',
      btn5: _vm.layoutbg.button_corner === '1',
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big',
      transapre: _vm.dataitem.button_type === 'Y'
    },style:({
      color: _vm.dataitem.text_color,
      background: _vm.dataitem.button_color,
      opacity: _vm.dataitem.opacity
    }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('el-image',{class:{
        btnimg: !_vm.type5,
        btnimg1: _vm.type5,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },attrs:{"src":_vm.dataitem.interlinkage_image}}):_vm._e(),_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3,
        line: _vm.dataitem.interlinkage_image != ''
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])],1):_vm._e(),(
      _vm.dataitem.link_type == 4 &&
        _vm.layoutbg.button_type_id != 9 &&
        _vm.dataitem.text_color != null &&
        _vm.layoutbg.button_type_id != 12
    )?_c('el-image',{staticClass:"wuwidth",attrs:{"src":_vm.dataitem.interlinkage_image},on:{"click":_vm.cilicknumber}}):_vm._e(),(_vm.layoutbg.button_type_id == 1 && _vm.dataitem.link_type != 4)?_c('div',{staticClass:"special-btn1",on:{"click":_vm.cilicknumber}},[_c('div',{class:{
        left: _vm.dataitem.title_type == 3,
        right: _vm.dataitem.title_type == 2,
        mini: _vm.dataitem.font_size == 'mini',
        smell: _vm.dataitem.font_size == 'smell',
        big: _vm.dataitem.font_size == 'big'
      }},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
          btnimg: !_vm.type5,
          btnimg1: _vm.type5,
          imgleft: _vm.dataitem.icon_wz == 4,
          imgright: _vm.dataitem.icon_wz == 2
        },style:({ 'margin-top': !_vm.type5 ? '-0.1rem !important' : '0px' }),attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
          btntext: true,
          block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
        }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])])]):_vm._e(),(_vm.layoutbg.button_type_id == 2 && _vm.dataitem.link_type != 4)?_c('div',{staticClass:"special-btn1",on:{"click":_vm.cilicknumber}},[_c('div',{class:{
        left: _vm.dataitem.title_type == 3,
        right: _vm.dataitem.title_type == 2,
        mini: _vm.dataitem.font_size == 'mini',
        smell: _vm.dataitem.font_size == 'smell',
        big: _vm.dataitem.font_size == 'big'
      },staticStyle:{"background":"#fff","color":"#000"}},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
          btnimg: !_vm.type5,
          btnimg1: _vm.type5,
          imgleft: _vm.dataitem.icon_wz == 4,
          imgright: _vm.dataitem.icon_wz == 2
        },style:({ 'margin-top': !_vm.type5 ? '-0.1rem !important' : '0px' }),attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
          btntext: true,
          block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
        }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])])]):_vm._e(),(_vm.layoutbg.button_type_id == 3 && _vm.dataitem.link_type != 4)?_c('div',{class:{
      'special-btn2': true,
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big'
    },style:({ color: _vm.dataitem.text_color, background: _vm.dataitem.button_color }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
        btnimg: !_vm.type5,
        btnimg1: _vm.type5,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },style:({ 'margin-top': !_vm.type5 ? '-0.1rem !important' : '0px' }),attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])]):_vm._e(),(_vm.layoutbg.button_type_id == 4 && _vm.dataitem.link_type != 4)?_c('div',{staticClass:"special-btn3",class:{
      'special-btn3': true,
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big'
    },style:({ color: _vm.dataitem.text_color, background: _vm.dataitem.button_color }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
        btnimg: !_vm.type5,
        btnimg1: _vm.type5,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },style:({ 'margin-top': !_vm.type5 ? '-0.1rem !important' : '0px' }),attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])]):_vm._e(),(_vm.layoutbg.button_type_id == 5 && _vm.dataitem.link_type != 4)?_c('div',{staticClass:"special-btn4",style:(_vm.setStyle(_vm.dataitem.button_color, _vm.dataitem.text_color)),on:{"click":_vm.cilicknumber}},[_c('div',{class:{
        left: _vm.dataitem.title_type == 3,
        right: _vm.dataitem.title_type == 2,
        mini: _vm.dataitem.font_size == 'mini',
        smell: _vm.dataitem.font_size == 'smell',
        big: _vm.dataitem.font_size == 'big'
      }},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
          btnimg: !_vm.type5,
          btnimg1: _vm.type5,
          imgleft: _vm.dataitem.icon_wz == 4,
          imgright: _vm.dataitem.icon_wz == 2
        },style:({ 'margin-top': !_vm.type5 ? '-0.1rem !important' : '0px' }),attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
          btntext: true,
          block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
        }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])])]):_vm._e(),(_vm.layoutbg.button_type_id == 8 && _vm.dataitem.link_type != 4)?_c('div',{staticClass:"special-btn5",style:({ color: _vm.dataitem.text_color }),on:{"click":_vm.cilicknumber}},[_c('div',{staticClass:"item"},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
          btnimg1: true,
          imgleft: _vm.dataitem.icon_wz == 4,
          imgright: _vm.dataitem.icon_wz == 2
        },attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
          btntext: true,
          block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
        }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])])]):_vm._e(),(_vm.layoutbg.button_type_id == 9 && _vm.dataitem.text_color != null)?_c('div',{class:{
      'special-btn6': true,
      wu: _vm.dataitem.link_type == 4,
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big',
      btn2: _vm.layoutbg.button_circle === '1',
      btn6: _vm.layoutbg.button_outline === '1',
      btn4: _vm.layoutbg.button_social === '1',
      btn5: _vm.layoutbg.button_corner === '1'
    },style:({ color: _vm.dataitem.text_color }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
        btnimg1: true,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])]):_vm._e(),(
      _vm.dataitem.text_color == null &&
        _vm.layoutbg.button_type_id == 9 &&
        _vm.dataitem.link_type == 4
    )?_c('img',{class:{
      btnimg4: true
    },attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"},on:{"click":_vm.cilicknumber}}):_vm._e(),(
      _vm.dataitem.text_color == null &&
        _vm.layoutbg.button_type_id != 9 &&
        _vm.dataitem.link_type == 4
    )?_c('img',{class:{
      btnimg4: true
    },attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"},on:{"click":_vm.cilicknumber}}):_vm._e(),(_vm.layoutbg.button_type_id == 10)?_c('div',{class:{
      btnitem: true,
      btn1: _vm.type1,
      btn2: _vm.layoutbg.button_circle === '1',
      btn6: _vm.layoutbg.button_outline === '1',
      btn4: _vm.layoutbg.button_social === '1',
      btn5: _vm.layoutbg.button_corner === '1',
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big',
      transapre: _vm.dataitem.button_type === 'Y'
    },style:({
      color: _vm.dataitem.text_color,
      background: _vm.dataitem.button_color,
      border: ' 0.053333rem solid #6b964f'
    }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('el-image',{class:{
        btnimg: !_vm.type5,
        btnimg1: _vm.type5,
        btnimg2: _vm.dataitem.icon_wz == 3,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },attrs:{"src":_vm.dataitem.interlinkage_image}}):_vm._e(),(_vm.dataitem.title != '')?_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3,
        line: _vm.dataitem.interlinkage_image != ''
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))]):_vm._e()],1):_vm._e(),(_vm.layoutbg.button_type_id == 11)?_c('div',{class:{
      'special-btn7': true,
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big'
    },style:({
      color: _vm.dataitem.text_color
    }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('el-image',{class:{
        btnimg: !_vm.type5,
        btnimg1: _vm.type5,
        btnimg2: _vm.dataitem.icon_wz == 3,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },attrs:{"src":_vm.dataitem.interlinkage_image}}):_vm._e(),(_vm.dataitem.title != '')?_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3,
        line: _vm.dataitem.interlinkage_image != ''
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))]):_vm._e(),_c('div',{staticClass:"rightboder"})],1):_vm._e(),(_vm.layoutbg.button_type_id == 12 && _vm.dataitem.text_color != null)?_c('div',{class:{
      'special-btn6': true,
      wu: _vm.dataitem.link_type == 4,
      left: _vm.dataitem.title_type == 3,
      right: _vm.dataitem.title_type == 2,
      mini: _vm.dataitem.font_size == 'mini',
      smell: _vm.dataitem.font_size == 'smell',
      big: _vm.dataitem.font_size == 'big',
      btn2: _vm.layoutbg.button_circle === '1',
      btn6: _vm.layoutbg.button_outline === '1',
      btn4: _vm.layoutbg.button_social === '1',
      btn5: _vm.layoutbg.button_corner === '1'
    },style:({ color: _vm.dataitem.text_color, background: _vm.dataitem.button_color }),on:{"click":_vm.cilicknumber}},[(_vm.dataitem.interlinkage_image != '')?_c('img',{class:{
        btnimg1: true,
        imgleft: _vm.dataitem.icon_wz == 4,
        imgright: _vm.dataitem.icon_wz == 2
      },attrs:{"src":_vm.dataitem.interlinkage_image,"fit":"contain"}}):_vm._e(),_c('span',{class:{
        btntext: true,
        block: _vm.dataitem.icon_wz == 3 || _vm.dataitem.link_type == 3
      }},[_vm._v(" "+_vm._s(_vm.dataitem.title))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }