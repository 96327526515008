<template>
  <div class="iphoneimage">
    <el-image :src="dataitem.url" style="width:100%" fit="contain"></el-image>
  </div>
</template>

<script>
export default {
  name: "iphoneimage",
  props: { dataitem: { type: Object } },
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="less" scoped>
.iphoneimage {
  padding-top: 0.4rem;
}
</style>
