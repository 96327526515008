<template>
  <div>
    <div class="stack-wrapper">
      <stack ref="stack" :pages="someList" :stackinit="stackinit"></stack>
      <el-image
        @click="prev"
        class=" prev"
        :src="require('../../util/images/icon_arrow_left.png')"
        fit="contain"
      ></el-image>

      <el-image
        @click="next"
        class=" next"
        :src="require('../../util/images/icon_arrow_right.png')"
        fit="contain"
      ></el-image>
    </div>
  </div>
</template>
<script>
import stack from "../stack.vue";
export default {
  name: "HelloWorld",
  props: { dataitem: { type: Object } },
  data() {
    return {
      someList: [],
      stackinit: {
        visible: 3
      }
    };
  },
  components: {
    stack
  },
  mounted() {
    const that = this;
    this.dataitem.test_image = JSON.parse(this.dataitem.test_image);

    that.dataitem.test_image.forEach(res => {
      var item = "";
      item =
        `<a href="http://` +
        res.url +
        `"  target="_blank"><img src="` +
        res.image +
        `" alt="` +
        res.title +
        `"/></a>`;
      var p = { html: "" };
      p.html = item;
      that.someList.push(p);
    });
  },
  methods: {
    prev() {
      this.$refs.stack.$emit("prev");
    },
    next() {
      this.$refs.stack.$emit("next");
    }
  }
};
</script>
<style lang="less" scoped>
.stack-wrapper {
  margin: 0 auto;
  position: relative;
  // z-index: 1000;
  // width: 320px;
  height: 7.453333rem;
  width: 100%;
  padding: 0;
  padding-top: 0.666667rem;
  list-style: none;
  pointer-events: none;
}
.controls {
  position: relative;
  width: 200px;
  text-align: center;
  display: flex; /*Flex布局*/
  display: -webkit-flex; /* Safari */
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 50px;
}
.controls .button {
  border: none;
  background: none;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  width: 50px;
  height: 50px;
  z-index: 100;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  background: transparent;
}
.button .next {
  display: inline-block;
  width: 10px;
  height: 5px;
  background: rgb(129, 212, 125);
  line-height: 0;
  font-size: 0;
  vertical-align: middle;
  -webkit-transform: rotate(45deg);
  left: -5px;
  top: 2px;
  position: relative;
}
.button .next:after {
  content: "/";
  display: block;
  width: 20px;
  height: 5px;
  background: rgb(129, 212, 125);
  -webkit-transform: rotate(-90deg) translateY(-50%) translateX(50%);
}
.button .prev {
  display: inline-block;
  width: 20px;
  height: 5px;
  background: rgb(230, 104, 104);
  line-height: 0;
  font-size: 0;
  vertical-align: middle;
  -webkit-transform: rotate(45deg);
}
.button .prev:after {
  content: "/";
  display: block;
  width: 20px;
  height: 5px;
  background: rgb(230, 104, 104);
  -webkit-transform: rotate(-90deg);
}
.controls .text-hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  color: transparent;
  display: block;
}
.prev {
  position: absolute;
  left: -0.4rem;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0.533333rem;
  height: 0.533333rem;
}
.next {
  position: absolute;
  right: -0.4rem;
  top: 0;
  width: 0.533333rem;
  height: 0.533333rem;
  bottom: 0;
  margin: auto;
}
</style>
